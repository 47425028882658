import axios from 'axios';
import { useEffect, useState } from 'react';

export interface YoutubeMeta {
  title: string;
  author_name: string;
  author_url: string;
  type: string;
  height: number;
  width: number;
  version: string;
  provider_name: string;
  provider_url: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  html: string;
}

const getMetadata = async (url: string): Promise<YoutubeMeta> => {
  return (await axios.get(`https://youtube.com/oembed?url=${url}&format=json`)).data;
};

export function useYoutubeMetadata({ url }: { url: string }) {
  const [meatadata, setMeatadata] = useState<YoutubeMeta | null>(null);

  useEffect(() => {
    (async () => {
      const r = await getMetadata(url);
      setMeatadata(r);
    })();
  }, []);

  return { meatadata };
}
