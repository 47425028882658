import classNames from 'classnames';
import produce from 'immer';
import { isEmpty, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import Modal from '../common/Modal';
import { getDisplayTimeBySeconds } from '../utils/player';
import './MyVersionPage.scss';
import { Card, FormValue } from './types';

export default function CardModal({
  open,
  card,
  setOpen,
  onSave,
}: {
  open: boolean;
  card?: Card | null;
  setOpen: (v: boolean) => void;
  onSave: (card: Card) => void;
}) {
  const [cardValues, setCardValues] = useState<FormValue<Card>>(card || null);

  useEffect(() => {
    setCardValues(card || null);
  }, [card, open]);

  if (!card || !cardValues) {
    return null;
  }

  const categoryNotAssigned = isEmpty(cardValues?.categories);

  return (
    <Modal open={open}>
      <div className="flex-col gap-4">
        <h3 className="font-bold text-lg">
          Create Card at {getDisplayTimeBySeconds(cardValues?.time)}
        </h3>
        <div className="flex-col gap-3">
          <div>
            <label className="label">Choose categories</label>
            <div className="">
              {['Improvise', 'Review', 'Material'].map((i) => {
                const selected = cardValues.categories?.includes(i);
                return (
                  <div
                    className={classNames(
                      'badge badge-lg mb-1 mr-1 cursor-pointer',
                      selected ? 'badge-secondary' : 'badge-outline'
                    )}
                    onClick={() => {
                      if (!selected) {
                        setCardValues({
                          ...cardValues,
                          categories: uniq([...cardValues.categories, i]),
                        });
                      } else {
                        setCardValues({
                          ...cardValues,
                          categories: cardValues.categories.filter((c) => c !== i),
                        });
                      }
                    }}
                  >
                    {i}
                  </div>
                );
              })}
            </div>
            <div>
              <label className="label">Your note</label>
              <textarea
                className="textarea textarea-bordered w-full"
                disabled={categoryNotAssigned}
                onChange={(e) => {
                  setCardValues(
                    produce(cardValues, (draft) => {
                      draft.content = e.target.value;
                    })
                  );
                }}
                value={cardValues?.content}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex gap-2 flex-1 justify-end">
          <button
            className="btn btn-outline"
            onClick={() => {
              setCardValues(null);
              setOpen(false);
            }}
          >
            Cancel
          </button>
          {!cardValues?.categories ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                if (cardValues?.id) {
                  onSave(cardValues);

                  setCardValues(null);
                  setOpen(false);
                }
              }}
            >
              Next
            </button>
          ) : (
            <button
              className="btn btn-primary"
              disabled={categoryNotAssigned}
              onClick={() => {
                if (cardValues?.id) {
                  onSave(cardValues);

                  setCardValues(null);
                  setOpen(false);
                }
              }}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
