import { useState } from 'react';
import { useNavigate } from 'react-router';

export default function YoutubeHomePage() {
  const [url, setUrl] = useState('');
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-xl py-3">{"Let's get started"}</h2>

        <div>
          <label className="label">YouTube URL</label>
          <textarea
            className="textarea textarea-bordered w-full"
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          ></textarea>
        </div>

        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/youtube/versions?url=${url}`);
            }}
          >
            Create My Version
          </button>
        </div>
      </div>
    </div>
  );
}
