var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ApolloClient, ApolloLink, from, InMemoryCache, } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { uniqBy } from 'lodash';
var createHttpLink = function (uri) {
    return createUploadLink({
        uri: uri,
        fetchOptions: {
            credentials: 'include',
        },
    });
};
var errorLinkRefreshToken = onError(function () {
    // eslint-disable-next-line
    console.log('error handle for refresh token');
    // refresh token error
    window.location.href = '/';
});
export function createClient(uri) {
    var client = new ApolloClient({
        link: from([errorLinkRefreshToken, createHttpLink(uri)]),
        cache: new InMemoryCache(),
    });
    return client;
}
var accessToken = null;
export function setAccessToken(value) {
    accessToken = value;
}
export function getAccessToken() {
    return accessToken;
}
var createErrorLink = function (uri) {
    return onError(
    // eslint-disable-next-line
    function (_a) {
        var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
        if (graphQLErrors) {
            graphQLErrors.forEach(function (_a) {
                var message = _a.message, locations = _a.locations, path = _a.path;
                // eslint-disable-next-line
                return console.log("[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
            });
        }
        if (networkError) {
            // @ts-ignore
            var statusCode = networkError.statusCode;
            if (statusCode === 401) {
                // const client = createClient();
                // return fromPromise(
                //   client
                //     .mutate({
                //       mutation: RefreshTokenDocument,
                //     })
                //     .then((r) => {
                //       setAccessToken(r.data.refreshToken?.jwt);
                //       return forward(operation);
                //     })
                // ).flatMap(() => {
                //   return forward(operation);
                // });
            }
            // eslint-disable-next-line
            console.log("[Network error]: ".concat(networkError));
        }
        return undefined;
    });
};
var createAuthMiddleware = function () {
    return new ApolloLink(function (operation, forward) {
        var at = getAccessToken();
        // add the authorization to the headers
        operation.setContext(function (_a) {
            var _b = _a.headers, headers = _b === void 0 ? {} : _b;
            return {
                headers: __assign(__assign({}, headers), (at
                    ? {
                        authorization: "Bearer ".concat(at),
                    }
                    : null)),
            };
        });
        return forward(operation);
    });
};
export function useClient(_a) {
    var uri = _a.uri;
    var client = new ApolloClient({
        link: from([createErrorLink(uri), createAuthMiddleware(), createHttpLink(uri)]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        podcasts: {
                            keyArgs: ['filters', 'sort'],
                            merge: function (existing, incoming) {
                                // load more and appending to the cached list
                                return __assign(__assign({}, existing), { data: __spreadArray(__spreadArray([], ((existing === null || existing === void 0 ? void 0 : existing.data) || []), true), ((incoming === null || incoming === void 0 ? void 0 : incoming.data) || []), true) });
                            },
                        },
                        usersPermissionsUsers: {
                            keyArgs: ['filters', 'sort'],
                            merge: function (existing, incoming) {
                                // load more and appending to the cached list
                                return __assign(__assign({}, existing), { data: __spreadArray(__spreadArray([], ((existing === null || existing === void 0 ? void 0 : existing.data) || []), true), ((incoming === null || incoming === void 0 ? void 0 : incoming.data) || []), true) });
                            },
                        },
                        comments: {
                            keyArgs: ['filters', 'sort'],
                            merge: function (existing, incoming) {
                                // load more and appending to the cached list
                                var comments = uniqBy(__spreadArray(__spreadArray([], ((existing === null || existing === void 0 ? void 0 : existing.data) || []), true), ((incoming === null || incoming === void 0 ? void 0 : incoming.data) || []), true), function (i) { return i.__ref; });
                                return __assign(__assign({}, existing), { data: comments });
                            },
                        },
                    },
                },
            },
        }),
    });
    return client;
}
