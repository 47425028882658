import { parseInt } from 'lodash';

export enum RHAP_UI {
  CURRENT_TIME = 'CURRENT_TIME',
  CURRENT_LEFT_TIME = 'CURRENT_LEFT_TIME',
  PROGRESS_BAR = 'PROGRESS_BAR',
  DURATION = 'DURATION',
  ADDITIONAL_CONTROLS = 'ADDITIONAL_CONTROLS',
  MAIN_CONTROLS = 'MAIN_CONTROLS',
  VOLUME_CONTROLS = 'VOLUME_CONTROLS',
  LOOP = 'LOOP',
  VOLUME = 'VOLUME',
}

export type AUDIO_PRELOAD_ATTRIBUTE = 'auto' | 'metadata' | 'none';

export type MAIN_LAYOUT = 'stacked' | 'stacked-reverse' | 'horizontal' | 'horizontal-reverse';

export type TIME_FORMAT = 'auto' | 'mm:ss' | 'hh:mm:ss';

export const getPosX = (event: TouchEvent | MouseEvent): number => {
  if (event instanceof MouseEvent) {
    return event.clientX;
  }
  return event.touches[0].clientX;
};

const addHeadingZero = (num: number): string => {
  return num > 9 ? num.toString() : `0${num}`;
};

export const getDisplayTimeBySeconds = (
  secondsRaw: number | string = 0,
  timeFormat: TIME_FORMAT = 'auto'
): string | null => {
  const seconds = typeof secondsRaw === 'number' ? secondsRaw : parseInt(secondsRaw);

  if (!Number.isFinite(seconds)) {
    return null;
  }

  const hrs = Math.floor(seconds / 60 / 60);
  const hrsStr = addHeadingZero(hrs);
  const min = Math.floor((seconds / 60) % 60);
  const minStr = addHeadingZero(min);
  const secStr = addHeadingZero(Math.floor(seconds % 60));

  const mmSs = `${minStr}:${secStr}`;
  const hhMmSs = `${hrsStr}:${minStr}:${secStr}`;

  if (timeFormat === 'auto') {
    if (hrs > 0) {
      return hhMmSs;
    }

    return mmSs;
  }
  if (timeFormat === 'mm:ss') {
    return mmSs;
  }

  return null;
};
