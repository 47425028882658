import classNames from 'classnames';
import produce from 'immer';
import { reverse, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaBackward, FaForward, FaPaperPlane, FaPause, FaPlay } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import YouTube, { YouTubePlayer } from 'react-youtube';
import { v4 } from 'uuid';
import { getDisplayTimeBySeconds } from '../utils/player';
import CardModal from './CardModal';
import './MyVersionPage.scss';
import { Card, FormValue } from './types';
import { useYoutubeMetadata } from './utils/use-youtube-metadata';

function getParams(url: string) {
  return new URL(url).searchParams;
}

export default function MyVersionPage() {
  const [query] = useSearchParams({ url: '' });
  const [url] = query.getAll('url');

  const [player, setPlayer] = useState<YouTubePlayer>();
  const [playing, setPlaying] = useState(false);
  const [curTime, setCurTime] = useState(0);

  const videoId = useMemo<string | null>(() => {
    const params = getParams(url);
    return params.get('v');
  }, [url]);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (player) {
      timer = setInterval(async () => {
        const t = await player.getCurrentTime();
        if (t) {
          setCurTime(t);
        }
      }, 200);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [player]);

  const { meatadata } = useYoutubeMetadata({
    url,
  });

  const [editingCard, setEditingCard] = useState<Card | null>(null);
  const [cards, setCards] = useState<Card[]>([]);

  const currCard = useMemo(() => {
    if (curTime != null && curTime >= 0) {
      const c = reverse([...cards]).find((card) => card.time <= curTime);
      return c;
    }

    return null;
  }, [cards, curTime]);
  const currCardIdx = useMemo(() => {
    if (currCard) {
      const c = cards.findIndex((card) => card.id === currCard.id);
      if (c > -1) {
        return c;
      }
    }

    return null;
  }, [cards, currCard]);

  const openCardModal = useCallback(
    async (card: FormValue<Card>) => {
      if (!card?.id) {
        return;
      }

      setEditingCard({
        ...card,
        time: curTime,
      });
    },
    [setCurTime, curTime]
  );

  const seekTo = useCallback(
    (time: number) => {
      setCurTime(time);
      player?.seekTo(time, true);
      player?.playVideo();
    },
    [player, setCurTime]
  );

  return (
    <div className="myversions px-2">
      <div className="flex flex-col gap-7">
        <h2 className="font-bold text-xl pt-5">{meatadata?.title}</h2>
        {videoId && (
          <div>
            <YouTube
              videoId={videoId || ''}
              opts={{
                width: '100%',
                height: 300,
                playerVars: {
                  autoplay: 0,
                },
              }}
              className="container"
              onReady={(event) => setPlayer(event.target)}
              onStateChange={(s) => {
                if (s.data === 1) {
                  setPlaying(true);
                } else {
                  setPlaying(false);
                }
              }}
            />
            <div className="vid-controls flex">
              <button
                className="flex gap-2"
                onClick={() => {
                  const nTime = curTime - 10;
                  setCurTime(nTime);
                  player?.seekTo(nTime, true);
                  player?.playVideo();
                }}
              >
                <FaBackward />
                <span>10s</span>
              </button>
              <button
                className="flex gap-2"
                onClick={() => {
                  const nTime = curTime - 5;
                  seekTo(nTime);
                }}
              >
                <FaBackward />
                <span>5s</span>
              </button>
              {playing ? (
                <button
                  className="flex"
                  onClick={() => {
                    player?.pauseVideo();
                    setPlaying(false);
                  }}
                >
                  <FaPause />
                </button>
              ) : (
                <button
                  className="flex"
                  onClick={() => {
                    player?.playVideo();
                    setPlaying(true);
                  }}
                >
                  <FaPlay />
                </button>
              )}
              <button
                className="flex gap-2"
                onClick={() => {
                  const nTime = curTime + 5;
                  seekTo(nTime);
                }}
              >
                <FaForward />
                <span>5s</span>
              </button>
              <button
                className="flex gap-2"
                onClick={() => {
                  const nTime = curTime + 10;
                  setCurTime(nTime);
                  player?.seekTo(nTime, true);
                  player?.playVideo();
                }}
              >
                <FaForward />
                <span>10s</span>
              </button>
              <button
                className="flex gap-2"
                onClick={() => {
                  if (currCardIdx != null) {
                    const nextCard = cards?.[currCardIdx - 1];

                    if (nextCard?.id) {
                      seekTo(nextCard.time);
                    }
                  }
                }}
              >
                Previous Card
              </button>
              <button
                className="flex gap-2"
                onClick={() => {
                  if (currCardIdx != null) {
                    const nextCard = cards?.[currCardIdx + 1];

                    if (nextCard?.id) {
                      seekTo(nextCard.time);
                    }
                  }
                }}
              >
                Next Card
              </button>
            </div>
          </div>
        )}

        <div className="flex gap-2">
          <button
            className="btn btn-primary btn-sm"
            onClick={async () => {
              openCardModal({
                id: v4(),
                time: curTime,
                categories: [],
              });
            }}
          >
            + New Card
          </button>
          <button className="btn btn-primary btn-sm gap-2">
            <FaPaperPlane />
            Share
          </button>
        </div>

        <ul className="card-list pt-3">
          {cards.map((card, idx) => {
            const isCardPlaying = currCard?.id === card.id;

            return (
              <li className="card-item flex" key={idx}>
                <div className="relative flex flex-col flex-1">
                  <span className="z-10 flex w-5 h-5 bg-primary rounded-full items-center justify-center">
                    <span className="text-white text-xs"></span>
                  </span>
                  <div className="z-0 absolute w-full h-full">
                    <div className="v-line w-1 mx-auto h-full bg-primary"></div>
                  </div>
                </div>
                <div className="box w-full text-left pb-4 px-2">
                  <div
                    className={classNames(
                      'border p-3 card gap-2',
                      isCardPlaying ? 'border-2 border-primary' : 'border'
                    )}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <div
                          className={classNames(
                            'time-badge badge gap-2 badge-lg cursor-pointer mr-1',
                            {
                              'time-active': isCardPlaying,
                            }
                          )}
                          onClick={() => {
                            // seekto
                            setCurTime(card.time);
                            player?.seekTo(card.time, true);
                            player?.playVideo();
                          }}
                        >
                          {getDisplayTimeBySeconds(card.time)}
                          <FaPlay className="play-icon" size={10} />
                        </div>
                        {card.categories.map((c) => (
                          <div
                            className={classNames(
                              'badge badge-lg mb-1 mr-1 cursor-pointer badge-secondary'
                            )}
                          >
                            {c}
                          </div>
                        ))}
                      </div>
                      <div
                        onClick={() => {
                          openCardModal(card);
                        }}
                      >
                        <FiEdit3 size={22} />
                      </div>
                    </div>
                    <div className="categories"></div>
                    <div className="content">{card.content}</div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <CardModal
        onSave={(card) => {
          const idx = cards.findIndex((c) => c.id === card.id);
          if (idx !== -1) {
            setCards(
              produce(cards, (draft) => {
                draft[idx] = card;
              })
            );
          } else {
            setCards(sortBy([...cards, card], 'time'));
          }
          setEditingCard(null);
        }}
        open={!!editingCard}
        setOpen={() => {
          setEditingCard(null);
        }}
        card={editingCard}
      />
    </div>
  );
}
