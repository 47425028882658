import { add } from '@aquadesk/common-lib';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import YouTube, { YouTubePlayer } from 'react-youtube';
import Layout from './Layout';
import MyVersionPage from './youtube/MyVersionPage';
import YoutubeHomePage from './youtube/YoutubeHomePage';

export default function AppRoutes() {
  const [player, setPlayer] = useState<YouTubePlayer>();

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<div>Welcome</div>} />

          <Route path="youtube" element={<YoutubeHomePage />} />
          <Route path="youtube/versions" element={<MyVersionPage />} />
          <Route
            path="testing"
            element={
              <div className="p-5">
                <p className="text-3xl font-bold">Hi there! 👋</p>
                <p>1 + 1 = {add(1, 1)}</p>
                <YouTube
                  videoId={'rAhXUkk6ppc'}
                  // onStateChange={(e) => {
                  //   console.log('e', e);
                  // }}
                  opts={{
                    width: 500,
                    height: 300,
                    playerVars: {
                      autoplay: 0,
                    },
                  }}
                  className="container"
                  onReady={(event) => setPlayer(event.target)}
                />

                <button
                  className="btn"
                  onClick={async () => {
                    if (player) {
                      const ctime = await player.getCurrentTime();

                      player?.seekTo(ctime + 20, true);
                    }
                  }}
                >
                  skip 5 sec
                </button>
              </div>
            }
          />
        </Route>
        <Route path="*" element={<div />} />
      </Routes>
    </Router>
  );
}
