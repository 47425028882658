import { ReactNode } from 'react';
import { Outlet } from 'react-router';
import Footer from './Footer';

function Layout({ children }: { children?: ReactNode }) {
  return (
    <div className="max-w-lg mx-auto">
      <div className="drawer-content flex flex-col">
        {children || <Outlet />}
        <div className="px-2">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
