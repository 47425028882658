import { ApolloProvider } from '@apollo/client';
import { useClient } from '@aquadesk/common-lib';
import AppRoutes from './AppRoutes';
import envs from './common/envs';

export default function App() {
  const client = useClient({ uri: envs.BackendUrl });

  return (
    <ApolloProvider client={client}>
      <AppRoutes />
    </ApolloProvider>
  );
}
